import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Typography } from '@webfx/core-web';
import { BulkActionsContext } from '@webfx/web-context';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import './BulkActions.css';

const BulkActions = ({
  actions,
  modalContentClass = '',
  modalDialogClass = '',
  modalClass = '',
  onClose = () => {},
  ignoreScroll = false,
}) => {
  const { selected, setSelected } = useContext(BulkActionsContext);
  const show = selected && selected.length !== 0 && !isEmpty(actions);

  React.useEffect(() => {
    if (selected.length > 0 && !ignoreScroll) {
      document.body.classList.add('force-scroll');
    }
    return () => {
      document.body.classList.remove('force-scroll');
    };
  }, [selected, ignoreScroll]);

  return (
    <Modal
      className={classnames('bulk-action-core-modal', modalClass)}
      show={show}
      onHide={() => {
        setSelected([]);
      }}
      backdrop={false}
      enforceFocus={false}
      autoFocus={false}
      contentClassName={modalContentClass}
      dialogClassName={modalDialogClass}
    >
      <div className="selection-info">
        <Typography bold variant="h1" className="text-light mb-0">
          {selected.length}
        </Typography>
        <Typography className="text-light">{`${
          selected.length === 1 ? 'Item' : 'Items'
        } selected`}</Typography>
      </div>
      <Modal.Body className="d-flex flex-row align-items-center">
        {actions.map(({ Renderer, ...action }, index) =>
          Renderer ? (
            <Renderer selected={selected} key={index} />
          ) : (
            <Button
              key={index}
              className="border-0 text-dark"
              wrapperClassName="d-flex flex-column"
              iconClassName="action-icon text-secondary p-0"
              icon={action.icon}
              text={action.text}
              onClick={() => action.action(selected)}
            />
          )
        )}
      </Modal.Body>
      <Button
        icon="close"
        className="rounded-circle mr-2 p-2 bulk-action-button text-secondary"
        onClick={() => {
          setSelected([]);
          onClose();
        }}
      />
    </Modal>
  );
};

export default BulkActions;
