import { createStore } from 'easy-peasy';

import common from './common';
import companies from './companies';
import config from './config';
import countries from './countries';
import imports from './imports';
import marketingStats from './marketingStats';
import preferences from './preferences';
import router from './router';
import sites from './sites';
import spreadsheets from './spreadsheets';
// import users from './users';
import usersSettings from './usersSettings';

const middlewares = [];

if (process.env.NODE_ENV === 'development' && process.env.POI_APP_STORE_LOG == 'true') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });
  middlewares.push(logger);
}

const store = createStore(
  {
    sites,
    config,
    companies,
    countries,
    imports,
    preferences,
    router,
    // users,
    common,
    usersSettings, // TODO: Deprecate
    marketingStats,
    spreadsheets,
  },
  {
    middleware: middlewares,
    devTools: process.env.NODE_ENV === 'development',
  }
);

export default store;
