import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import numbro from 'numbro';
import classnames from 'classnames';

import IconLabel from '../IconLabel/IconLabel';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import DownloadItem from './DownloadItem/DownloadItem';
import './Downloads.style.css';

const Downloads = () => {
  const [isMinimized, setMinimized] = useState(false);
  const { downloads } = useStoreState((state) => state.downloads);
  const { clearAllDownloads } = useStoreActions((actions) => actions.downloads);

  const progress = numbro(
    downloads.reduce((accum, download) => accum + download.progress, 0) / downloads.length
  ).format({
    trimMantissa: true,
    mantissa: 1,
  });

  return downloads.length > 0 ? (
    <div
      className={classnames({
        'downloads-wrapper': true,
        minimized: isMinimized,
      })}
      data-fx-name="downloadWindow"
    >
      <div className="downloads-header">
        <IconLabel
          labelClassName="bold"
          iconClassName="download-icon"
          icon="system_update_alt"
          label={`Downloads (${downloads.length})`}
          className="flex-grow-1"
        />
        <Typography className="text-secondary font-12 mb-0 mr-2">{progress}%</Typography>
        {progress != 100 ? (
          <Button
            icon="stop"
            variant="light"
            onClick={() => {
              clearAllDownloads();
            }}
            className="downloads-clearall mr-2 bg-white"
            iconClassName="downloads-clearall-icon"
            data-fx-name="stopDownloadButton"
          />
        ) : null}
        {progress == 100 ? (
          <Button
            text="Clear"
            variant="light"
            onClick={() => {
              clearAllDownloads();
            }}
            className="mr-2 bg-white border-0"
          />
        ) : null}
        <Button
          icon={isMinimized ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          variant="light"
          className="border-0 p-0 text-dark bg-white"
          onClick={() => {
            setMinimized(!isMinimized);
          }}
        />
      </div>
      <div className="download-items" data-fx-name="exportForm">
        {downloads.map((download) => (
          <DownloadItem key={download.filename} download={download} />
        ))}
      </div>
    </div>
  ) : null;
};

export default Downloads;
